(function ($) {
  'use strict';

  $.fn.disableButton = function () {
    this.each(function () {
      $(this)
        .css('pointer-events', 'none')
        .addClass('disabled')
        .attr('disabled', 'disabled');
    });

    return this;
  };
})(jQuery);

(function ($) {
  'use strict';

  $.fn.enableButton = function () {
    this.each(function () {
      $(this)
        .css('pointer-events', 'auto')
        .removeClass('disabled')
        .removeAttr('disabled');
    });

    return this;
  };
})(jQuery);
