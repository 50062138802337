import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocalObservable } from 'mobx-react-lite';

export default observer(({ children }) => {
  const localStore = useLocalObservable(() => ({
    key: 0,
  }));

  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        localStore.key += 1;
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  return <span key={localStore.key}>{children}</span>;
});
