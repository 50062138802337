import textBalancer from 'text-balancer';

export default function () {
  const balanceTextOnScreen = () => {
    textBalancer.balanceText();
  };

  const balanceTextWhenPrinting = () => {
    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(() => {
        textBalancer.balanceText();
      });
    }
  };

  balanceTextOnScreen();
  balanceTextWhenPrinting();
}
