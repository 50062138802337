import balanceText from '~/javascripts/utils/balanceText';

const attachBehavior = (module, el) => {
  module.default($(el));
};

const modules = import.meta.glob('~/javascripts/behaviors/**/*.jsx');

const attachBehaviors = (event) => {
  let elements;

  if (event.type == 'ModalOpened') {
    elements = document.querySelectorAll('.modal [data-behavior]');
  } else if (event.type == 'PaginationLoaded') {
    elements = document.querySelectorAll('.pagination-wrapper [data-behavior]');
  } else {
    elements = document.querySelectorAll('[data-behavior]');
  }

  Array.from(elements).forEach((el) => {
    const behaviors = el.dataset.behavior.split(' ');

    behaviors.forEach((behavior) => {
      if (
        $('body').hasClass('builder-page') &&
        event.type == 'DOMContentLoaded' &&
        behavior == 'Modal'
      ) {
        return;
      }
      modules[`/javascripts/behaviors/${behavior}.jsx`]().then((module) => {
        attachBehavior(module, el);
      });
    });
  });
};

document.addEventListener('DOMContentLoaded', (e) => {
  balanceText();
  attachBehaviors(e);
});
document.addEventListener('ModalOpened', attachBehaviors);
document.addEventListener('PaginationLoaded', attachBehaviors);
document.addEventListener('BuilderContentLoaded', (e) => {
  balanceText();
  attachBehaviors(e);
});
