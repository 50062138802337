import { BuilderComponent } from '@builder.io/react';

export default function BuilderResource({
  content,
  locale,
  resourceName,
  theme,
  customComponentProps,
}) {
  const handleContentLoaded = () => {
    setTimeout(
      () => document.dispatchEvent(new CustomEvent('BuilderContentLoaded')),
      500
    );
  };

  return (
    <>
      <BuilderComponent
        content={content}
        contentLoaded={() => handleContentLoaded()}
        context={{ customComponentProps: customComponentProps }}
        data={{ theme: theme }}
        locale={locale}
        model={resourceName}
      />
    </>
  );
}
