import Cookies from 'js-cookie';

const gtmEventsToTrack = [
  'Campaign Created',
  'Donation Completed',
  'Joined Team',
  'Lead Form Completed',
  'Subscription Downgraded',
  'Subscription Payment Method Switched to Bank',
  'Subscription Upgraded',
  'Team Created',
  'User Registered',
];
const optimizelyEventsToTrack = ['Donation Completed', 'Lead Form Completed'];
const eventsToTrack = gtmEventsToTrack.concat(optimizelyEventsToTrack);

const cookieName = (eventName) =>
  `track_${eventName.toLowerCase().replace(/ /g, '_')}`;

const cookieForEvent = (eventName) => Cookies.get(cookieName(eventName));

const shouldTrackEvent = (eventName) => !!cookieForEvent(eventName);

const deleteCookieForEvent = (eventName) => {
  Cookies.remove(cookieName(eventName));
};

const waitForOptimizely = (counter, callback) => {
  counter++;

  if (window.optimizely?.initialized || counter > 30) {
    callback();
  } else {
    setTimeout(() => {
      waitForOptimizely(counter, callback);
    }, 100);
  }
};

const trackEventsOnPageLoad = () => {
  eventsToTrack.forEach((eventName) => {
    if (shouldTrackEvent(eventName)) {
      const { props } = JSON.parse(
        cookieForEvent(eventName).replace(/\+/g, ' ')
      );

      if (gtmEventsToTrack.includes(eventName)) {
        window.track_event(eventName, props);
      }

      if (optimizelyEventsToTrack.includes(eventName)) {
        const tags =
          eventName === 'Donation Completed'
            ? { revenue: Math.round(props.revenue * 100) }
            : {};

        const counter = 0;
        waitForOptimizely(counter, () => {
          window.optimizely.push({
            type: 'event',
            eventName: eventName,
            tags: tags,
          });
        });
      }

      deleteCookieForEvent(eventName);
    }
  });

  return null;
};

export default trackEventsOnPageLoad;
