import Twitch from 'react-player/twitch';
import Vimeo from 'react-player/vimeo';
import YouTube from 'react-player/youtube';
import t from '~/javascripts/utils/translation.js';
import { EmailValidator } from 'commons-validator-js';
import { isEmpty } from 'lodash';

Parsley.options.excluded =
  'input[type=button], input[type=submit], input[type=reset], :hidden';
Parsley.options.errorClass = 'error';

Parsley.addValidator('currency', (value, requirement) => {
  value = value
    .trim()
    .replace('$', '')
    .replace(',', '')
    .replace('undefined', '');
  const currencyWithCents = new RegExp('^[0-9]*\\.[0-9]{0,2}$');
  const currencyWithoutCents = new RegExp('^[0-9]*$');
  const minDonation = parseFloat(requirement.split(',')[0]);
  const maxDonation = parseFloat(requirement.split(',')[1]);

  const isCurrencyWithCents = function () {
    return value.indexOf('.') > -1 && currencyWithCents.test(value);
  };

  const isCurrencyWithoutCents = function () {
    return currencyWithoutCents.test(value);
  };

  const isCurrency = function () {
    return isCurrencyWithCents() || isCurrencyWithoutCents();
  };

  const isParseableFloat = function () {
    const parseableValue = value === '' ? '0' : value;
    return (
      parseFloat(parseableValue) !== 0 && !isNaN(parseFloat(parseableValue))
    );
  };

  const isNotTooLarge = function () {
    return parseFloat(value) <= maxDonation;
  };

  const isNotTooSmall = function () {
    return parseFloat(value) >= minDonation;
  };

  return (
    isParseableFloat() && isCurrency() && isNotTooSmall() && isNotTooLarge()
  );
});

Parsley.addValidator('emailTld', (value) => {
  const validator = new EmailValidator();
  return validator.isValid(value);
});

Parsley.addValidator('socialNetworkUsername', (value) => {
  const socialNetworkUsernamePattern = /^((?![/@]).)*$/;
  return socialNetworkUsernamePattern.test(value);
});

Parsley.addValidator(
  'intlTelInputPhoneNumber',
  (_value, countryRequirement) => {
    const errorCode = window.iti.getValidationError();

    const errorMessage = () => {
      switch (errorCode) {
        case intlTelInputUtils.validationError.IS_POSSIBLE:
          if (!passesCountryRequirement()) {
            return 'Currently only US numbers are eligible to receive texts';
          } else {
            return 'Please enter a valid phone number';
          }
        case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
          return 'Please enter a valid country code';
        case intlTelInputUtils.validationError.TOO_SHORT:
          return 'Your phone number is too short';
        case intlTelInputUtils.validationError.TOO_LONG:
          return 'Your phone number is too long';
        case intlTelInputUtils.validationError.NOT_A_NUMBER:
          return 'Please enter a valid phone number';
      }
    };

    const passesCountryRequirement = () => {
      if (countryRequirement != 'us-only') {
        return true;
      }

      const selectedCountry = $('[name="phone_number_country"]').val();
      return selectedCountry === 'us';
    };

    Parsley.addMessage('en', 'intlTelInputPhoneNumber', errorMessage());

    return window.iti.isValidNumber() && passesCountryRequirement();
  }
);

Parsley.addValidator('notACreditCardNumber', (value) => {
  const cardPattern = /[3456]\d{11,18}/;
  return !cardPattern.test(value.replace(/[^\d]/g, ''));
});

Parsley.addValidator('listOfValidDeploymentCodes', (value) => {
  const deploymentCodePattern =
    /^[A-Z]{2}\.[A-Z]{3}(?:\.[1-2][H]|\.[A-Z][1-4])\.\d{2}\.(\d{3})(?:\.|-)(\d+)$/;
  let valid = true;

  value.split('\n').forEach((deploymentCode) => {
    valid = valid && deploymentCodePattern.test(deploymentCode);
  });

  return valid;
});

Parsley.addValidator(
  'validUrl',
  (url) => YouTube.canPlay(url) || Twitch.canPlay(url) || Vimeo.canPlay(url)
);

Parsley.addValidator('ukPostcode', (postcode, country) => {
  if (country != 'GB') return true;

  let errorMessage = '';

  if (isEmpty(postcode.trim())) {
    errorMessage = t('views.donate_form.zipcode.error');
  } else if (!postcode.trim().includes(' ')) {
    errorMessage = t('views.donate_form.postcode.error.missing_space');
  } else if (!new RegExp('^[a-z]+$', 'i').test(postcode.charAt(0))) {
    errorMessage = t(
      'views.donate_form.postcode.error.does_not_start_with_a_letter'
    );
  } else {
    return true;
  }

  Parsley.addMessage('en', 'ukPostcode', errorMessage);

  return false;
});
