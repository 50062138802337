import BackCacheFix from '~/javascripts/components/BackCacheFix';
import { createElement } from 'react';
import { render } from 'react-dom';

const renderComponent = (module, props, el) => {
  const component = createElement(module.default, props);
  render(<BackCacheFix>{component}</BackCacheFix>, el);
};

const modules = import.meta.glob('~/javascripts/components/**/*.jsx');

export default (el) => {
  const props = JSON.parse(el.dataset.props || '{}');

  modules[`/javascripts/components/${el.dataset.component}.jsx`]().then(
    (module) => {
      renderComponent(module, props, el);
    }
  );
};
